import { useAppSelector, useAppDispatch } from '../../../redux/hooks'
import { showLeftSideBar, swapRightSideBar, selectAppState, RightSideBarType } from '../../../redux/features/appSlice'
import { selectChannelState } from '../../../redux/features/channelSlice'
import {
  selectSubscriptionsState,
  addSubscription,
  removeSubscription,
} from '../../../redux/features/subscriptionsSlice'
import {
  Label,
  Stack,
  mergeStyleSets,
  IconButton,
  IButtonStyles,
  PrimaryButton,
  DefaultButton,
  OverflowSet,
  IOverflowSetItemProps,
} from '@fluentui/react'
import { FontSizes } from '@fluentui/theme'
import { useAccount } from '@azure/msal-react'
import { useOnSignIn, useIsChannelSelected, useNavigate } from '../../../hooks'
import { useTranslation } from 'react-i18next'
import { selectPostsState } from '../../../redux/features/postsSlice'
import { notEmptyFilter, SubscriptionId } from '@notidar/content'

const getClassNames = () => {
  return mergeStyleSets({
    container: {
      height: '100%',
    },
    overflowIcon: {
      width: 60,
      height: 60,
    },
    title: {
      fontSize: FontSizes.xLargePlus,
      padding: '0 10px',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    center: {
      height: '100%',
      width: '100%',
      flexWrap: 'nowrap',
      overflow: 'hidden',
    },
    centerTitle: {
      height: '100%',
      overflow: 'hidden',
    },
    centerSubscribe: {
      height: '100%',
      margin: '0 10px 0 0',
    },
  })
}

const buttonIconStyles: IButtonStyles = {
  root: {
    width: 60,
    height: 60,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    fontSize: 20,
  },
  menuIcon: {
    fontSize: 20,
  },
}

export const Header = () => {
  const classNames = getClassNames()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const account = useAccount()
  const onSignIn = useOnSignIn()
  const { channel } = useAppSelector(selectChannelState)
  const { filters, channelId: postsCannelId } = useAppSelector(selectPostsState)
  const { isLeftSideBarVisible, screenType: appMode } = useAppSelector(selectAppState)
  const { subscriptions, status, subscriptionsActionStatus } = useAppSelector(selectSubscriptionsState)
  const dispatch = useAppDispatch()
  const isChannelSelected = useIsChannelSelected()

  const onShowLeftSideBar = () => {
    dispatch(showLeftSideBar())
  }

  const onSwapFiltersRightSideBar = () => {
    dispatch(swapRightSideBar(RightSideBarType.Filters))
  }

  const onSwapNotificationsRightSideBar = () => {
    dispatch(swapRightSideBar(RightSideBarType.Notifications))
  }

  const onSwapTogglesRightSideBar = () => {
    dispatch(swapRightSideBar(RightSideBarType.Toggles))
  }

  const onAddSubscription = async () => {
    if (channel?.channelId && subscriptionsActionStatus !== 'loading') {
      const actualFilters = postsCannelId === channel.channelId ? filters.filter(notEmptyFilter) : [];
      const subscription = await dispatch(addSubscription({ channelId: channel.channelId, filters: actualFilters })).unwrap();
      if (subscription) {
        navigate(`/channel/${subscription.displayName}/subscription/${subscription.subscriptionId}`);
      }
    }
  }

  const onRemoveSubscription = async (subscriptionId: SubscriptionId) => {
    if (channel?.channelId && subscriptionsActionStatus !== 'loading') {
      await dispatch(removeSubscription({ channelId: channel.channelId, subscriptionId })).unwrap();
      navigate(`/channel/${channel.name}`);
    }
  }

  function getSubscriptionButton() {
    if (!account) {
      return <PrimaryButton onClick={onSignIn}>{t('header.subscribe')}</PrimaryButton>
    }

    if (status !== 'succeeded') {
      return null
    }

    const subscription = subscriptions?.find(x => x.channelId === channel?.channelId)
    if (subscription) {
      return <DefaultButton onClick={() => onRemoveSubscription(subscription.subscriptionId)}>{t('header.subscribed')}</DefaultButton>
    } else {
      return <PrimaryButton onClick={onAddSubscription}>{t('header.subscribe')}</PrimaryButton>
    }
  }

  const onRenderItem = (item: IOverflowSetItemProps): JSX.Element | null => {
    if (item.hidden) {
      return null
    }

    return (
      <IconButton
        styles={buttonIconStyles}
        iconProps={item.iconProps}
        title={item.name}
        aria-label={item.name}
        onClick={item.onClick}
      />
    )
  }

  const onRenderOverflowButton = (overflowItems: any[] | undefined): JSX.Element | null => {
    const overflowItemsToRender = overflowItems?.filter(x => !x.hidden)
    if (!overflowItemsToRender || overflowItemsToRender.length === 0) {
      return null
    }

    return (
      <IconButton
        styles={buttonIconStyles}
        title={t('header.more')}
        aria-label={t('header.more')}
        className={classNames.overflowIcon}
        menuIconProps={{ iconName: 'More' }}
        menuProps={{ items: overflowItemsToRender }}
      />
    )
  }

  return (
    <Stack horizontal horizontalAlign='space-between' className={classNames.container}>
      <Stack.Item disableShrink>
        {!isLeftSideBarVisible && (
          <Stack.Item disableShrink>
            <IconButton
              title={t('header.menu')}
              aria-label={t('header.menu')}
              onClick={onShowLeftSideBar}
              styles={buttonIconStyles}
              iconProps={{ iconName: 'GlobalNavButton' }}
            />
          </Stack.Item>
        )}
      </Stack.Item>
      <Stack verticalAlign='center' horizontal horizontalAlign='start' className={classNames.center}>
        <Stack verticalAlign='center' horizontal className={classNames.centerTitle}>
          {isChannelSelected && <Label className={classNames.title}>{channel?.title}</Label>}
        </Stack>
        <Stack verticalAlign='center' horizontal className={classNames.centerSubscribe}>
          {isChannelSelected && getSubscriptionButton()}
        </Stack>
      </Stack>
      <Stack.Item disableShrink>
        <Stack verticalAlign='center' horizontal>
          <OverflowSet
            onRenderOverflowButton={onRenderOverflowButton}
            onRenderItem={onRenderItem}
            items={[
              {
                key: 'filter',
                iconProps: { iconName: 'Filter' },
                name: t('header.filters'),
                hidden: !isChannelSelected || appMode === 'mobile',
                onClick: onSwapFiltersRightSideBar,
              },
              {
                key: 'notifications',
                iconProps: { iconName: 'Ringer' },
                name: t('header.notifications'),
                hidden: !isChannelSelected || appMode === 'mobile',
                onClick: onSwapNotificationsRightSideBar,
              },
              {
                key: 'toggles',
                iconProps: { iconName: 'Equalizer' },
                name: t('header.toggles'),
                hidden: !account || appMode === 'mobile',
                onClick: onSwapTogglesRightSideBar,
              },
            ]}
            overflowItems={[
              {
                key: 'filter',
                iconProps: { iconName: 'Filter' },
                name: t('header.filters'),
                hidden: !isChannelSelected || appMode === 'desktop',
                onClick: onSwapFiltersRightSideBar,
              },
              {
                key: 'notifications',
                iconProps: { iconName: 'Ringer' },
                name: t('header.notifications'),
                hidden: !isChannelSelected || appMode === 'desktop',
                onClick: onSwapNotificationsRightSideBar,
              },
              {
                key: 'toggles',
                iconProps: { iconName: 'Equalizer' },
                name: t('header.toggles'),
                hidden: !account || appMode === 'desktop',
                onClick: onSwapTogglesRightSideBar,
              },
            ]}
          />
        </Stack>
      </Stack.Item>
    </Stack>
  )
}
