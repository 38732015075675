import { DefaultButton, Dropdown, IDropdownOption, IStackTokens, Stack } from '@fluentui/react'
import { AuthorPostSection, FieldType, PostSection, PostSectionType } from '@notidar/api'
import { SectionEditorComponentProps } from '../Shared.types'
import { useEffect, useState } from 'react'
import { getFieldByFieldKey } from '../../utils'
import { useTranslation } from 'react-i18next'

const stackTokens: IStackTokens = { childrenGap: 10 }

const isValid = (section: Partial<AuthorPostSection>): boolean => {
  return section.authorField !== undefined
}

export interface AuthorSectionEditorComponentProps
  extends SectionEditorComponentProps<PostSectionType.Author> { }

export const AuthorSectionEditorComponent = ({
  fields,
  hidden,
  onUpdate,
  section,
}: AuthorSectionEditorComponentProps) => {
  const { t } = useTranslation();
  const [sectionState, setSectionState] = useState<Partial<AuthorPostSection> & PostSection>(section)

  const allowedAuthorFields = fields
    .filter(x => [FieldType.String, FieldType.StringList, FieldType.Enum, FieldType.EnumList].includes(x.type))
    .map(x => ({ key: x.name, text: x.displayName ? `${x.displayName} (${x.name})` : x.name }))

  const allowedDateTimeFields = fields
    .filter(x => x.type === FieldType.DateTime)
    .map(x => ({ key: x.name, text: x.displayName ? `${x.displayName} (${x.name})` : x.name }))

  const allowedLinkFields = fields
    .filter(x => x.type === FieldType.Link)
    .map(x => ({ key: x.name, text: x.displayName ? `${x.displayName} (${x.name})` : x.name }))

  useEffect(() => {
    onUpdate(sectionState, isValid(sectionState))
  }, [sectionState]) // eslint-disable-line react-hooks/exhaustive-deps

  const onAuthorChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    const newState: Partial<AuthorPostSection> & PostSection = {
      ...sectionState,
      authorField: option?.key as string,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onAuthorClear = (): void => {
    const newSection: Partial<AuthorPostSection> & PostSection = {
      ...sectionState,
      authorField: undefined
    }
    setSectionState(newSection)
    onUpdate(newSection, isValid(newSection))
  }

  const onLinkChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    const newState: Partial<AuthorPostSection> & PostSection = {
      ...sectionState,
      linkField: option?.key as string,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onLinkClear = (): void => {
    const newSection: Partial<AuthorPostSection> & PostSection = {
      ...sectionState,
      linkField: undefined
    }
    setSectionState(newSection)
    onUpdate(newSection, isValid(newSection))
  }

  const onDateTimeChange = (
    event: React.FormEvent<HTMLDivElement>,
    option?: IDropdownOption,
    index?: number
  ): void => {
    const newState: Partial<AuthorPostSection> & PostSection = {
      ...sectionState,
      dateTimeField: option?.key as string,
    }
    setSectionState(newState)
    onUpdate(newState, isValid(newState))
  }

  const onDateTimeClear = (): void => {
    const newSection: Partial<AuthorPostSection> & PostSection = {
      ...sectionState,
      dateTimeField: undefined
    }
    setSectionState(newSection)
    onUpdate(newSection, isValid(newSection))
  }

  const authorField = getFieldByFieldKey({ fields }, sectionState.authorField)
  const linkField = getFieldByFieldKey({ fields }, sectionState.linkField)
  const dateField = getFieldByFieldKey({ fields }, sectionState.dateTimeField)

  if (hidden) {
    return null
  }

  return (
    <Stack>
      <Stack tokens={stackTokens} horizontal wrap verticalAlign='end'>
        <Stack.Item grow>
          <Dropdown
            key={sectionState.authorField}
            disabled={allowedAuthorFields.length === 0}
            onChange={onAuthorChange}
            selectedKey={authorField ? sectionState.authorField : undefined}
            placeholder={
              allowedAuthorFields.length === 0 ? t("content.sections.shared.no_compatible_fields") : t("content.sections.shared.select_field_placeholder")
            }
            options={allowedAuthorFields}
            label={t("content.sections.author.author_field")}
          />
        </Stack.Item>
        <Stack.Item disableShrink grow={0}>
          <DefaultButton
            disabled={!sectionState.authorField}
            onClick={onAuthorClear}
            text={t("content.sections.shared.clear")}
          />
        </Stack.Item>
      </Stack>
      <Stack tokens={stackTokens} horizontal wrap verticalAlign='end'>
        <Stack.Item grow>
          <Dropdown
            key={sectionState.linkField}
            disabled={allowedLinkFields.length === 0}
            onChange={onLinkChange}
            selectedKey={linkField ? sectionState.linkField : undefined}
            placeholder={
              allowedLinkFields.length === 0 ? t("content.sections.shared.no_compatible_fields") : t("content.sections.shared.select_field_placeholder")
            }
            options={allowedLinkFields}
            label={t("content.sections.author.link_field")}
          />
        </Stack.Item>
        <Stack.Item disableShrink grow={0}>
          <DefaultButton
            disabled={!sectionState.linkField}
            onClick={onLinkClear}
            text={t("content.sections.shared.clear")}
          />
        </Stack.Item>
      </Stack>
      <Stack tokens={stackTokens} horizontal wrap verticalAlign='end'>
        <Stack.Item grow>
          <Dropdown
            key={sectionState.dateTimeField}
            disabled={allowedDateTimeFields.length === 0}
            onChange={onDateTimeChange}
            selectedKey={dateField ? sectionState.dateTimeField : undefined}
            placeholder={
              allowedDateTimeFields.length === 0 ? t("content.sections.shared.no_compatible_fields") : t("content.sections.shared.select_field_placeholder")
            }
            options={allowedDateTimeFields}
            label={t("content.sections.author.datetime_field")}
          />
        </Stack.Item>
        <Stack.Item disableShrink grow={0}>
          <DefaultButton
            disabled={!sectionState.dateTimeField}
            onClick={onDateTimeClear}
            text={t("content.sections.shared.clear")}
          />
        </Stack.Item>
      </Stack>
    </Stack>
  )
}
